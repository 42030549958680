export const platformConstants = {
  FETCH_REQUEST: 'PLATFORM_FETCH_REQUEST',
  FETCH_SUCCESS: 'PLATFORM_FETCH_SUCCESS',
  FETCH_FAILURE: 'PLATFORM_FETCH_FAILURE',

  UPDATE_REQUEST: 'PLATFORM_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PLATFORM_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PLATFORM_UPDATE_FAILURE',

  UPDATE_ACTIVE: 'PLATFORM_UPDATE_ACTIVE',

  PREORDER_DELAY_KEY: 'preorder-duration',
  DELIVERY_DELAY_KEY: 'preorder-delivery',
  PREORDER_DATE_BEGIN_KEY: 'preorder-date-begin',
  NEW_DELAY_KEY: 'new-delay',
  STOCK_DISPLAY_OUT_KEY: 'stock-display-out',
  STOCK_CART_KEY: 'stock-cart',
  ORDERED_BY_KEY: 'ordered-by',
  DELIVERY_MODE_KEY: 'delivery-mode',
  HAS_DRY_PRODUCTS_KEY: 'has-dry-products',
  HAS_FRESH_PRODUCTS_KEY: 'has-fresh-products',
  HAS_FROZEN_PRODUCTS_KEY: 'has-frozen-products',
  HAS_MOQ_KEY: 'has-moq',
  HAS_CONTAINERIZATION_KEY: 'has-containerization',
  KEY_HAS_CONTAINERIZATION_V2: 'has-containerization-v2',
  HAS_TECHNICAL_SHEETS_KEY: 'has-technical-sheets',
  HAS_CREDIT_KEY: 'has-credit',
  NEGOCIABLE_PERCENTAGE: 'negociable-percentage',
  HAS_PLATFORM_EXPORT_KEY: 'has-platform-export',
  KEY_HAS_EXPORT_CATALOG_FILE: 'has-export-catalog-file',
  USE_DEGRESSIVE_PRICE: 'use-degressive-price',
};
