import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Moment from 'moment';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import CommercialOrderForm from 'components/CommercialOrderForm/CommercialOrderForm';
import { BsTrash } from '@react-icons/all-files/bs/BsTrash';
import { CommercialOrderWrapper } from 'pages/CommercialOrder/CommercialOrder.style';
import Calendar from 'components/Core/Calendar/Calendar';
import { getAvailableTemperatures } from 'helpers/Platform';
import { selectCurrentPlatform } from 'selectors/platform';
import { isValidDeliveryDay } from 'helpers/Cart';
import { cartActions, cartItemsActions, dayOffActions, modalActions } from 'actions';
import { toastActions } from 'actions/Toast';
import {
  formatPriceWithCurrency,
  getCollection,
  getDegressivePrice,
  getPath,
  history,
} from 'helpers';
import { selectCartCollectionByFilter, selectCartError, selectValidatedCart } from 'selectors/cart';
import TypeIcon from 'components/Core/Icon/TypeIcon';
import PromotionIcon from 'components/Icon/PromotionIcon';
import WarningIcon from 'components/CommercialOrderForm/WarningIcon';
import { getFormatedDate } from 'helpers/Utils';
import Spinner from 'components/Core/Spinner/Spinner';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { toastConstants } from 'constants/Toast.constants';
import DegressivePrices from 'components/Core/DegressivePrices';
import { cartConstants, catalogConstants } from '../../constants';

const CommercialOrder = ({
  currentPlatform,
  fetchDayOff,
  dayOff,
  openCommercialOrderValidationModal,
  storeCart,
  cartError,
  cartCollection,
  validatedCart,
  pushToast,
  setErrorToasted,
  updateDeliveryDates,
  addItemToCart,
  saveItem,
  deleteFromCart,
  openDeleteModal,
  updateCart,
}) => {
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [cart, setCart] = useState(null);
  const [comment, setComment] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCartInitialized, setIsCartInitialized] = useState(false);
  let timer = null;

  useEffect(() => {
    fetchDayOff({ platform: currentPlatform.id });
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    if (history.location.state && history.location.state.cart) {
      const historyCart = history.location.state.cart;
      setCart(historyCart);
      setIsCartInitialized(true);
      setDeliveryDate(historyCart.delivery_dates_by_type);
      setComment(historyCart.comment);
    }

    return () => {
      clearState();
    };
  }, []);

  useEffect(() => {
    if (
      cartCollection &&
      cartCollection.items[0] &&
      !cartCollection.isLoading &&
      !cartError.message &&
      isCartInitialized
    ) {
      setCart(cartCollection.items[0]);
    }
  }, [cartCollection]);

  useEffect(() => {
    if (cart && validatedCart && validatedCart.id === cart.id) {
      setShouldBlockNavigation(false);
      pushToast({
        type: toastConstants.TYPE_SUCCESS,
        title: '',
        message: 'Commande validée !',
      });
      clearState();
    }
  }, [validatedCart]);

  useEffect(() => {
    if (cartError.message && !cartError.message.toasted) {
      pushToast({
        type: 'error',
        title: '',
        message: "Une erreur s'est produite, veullez réesayer plus tard",
      });
      setErrorToasted();
    }
  }, [cartError]);

  const clearState = () => {
    setSelectedClient(null);
    setSelectedItem(null);
    setCartItems([]);
    setDeliveryDate(null);
    setIsCartInitialized(false);
    setShouldBlockNavigation(false);
    setComment('');
    setCart(null);
  };

  const onProductQuantityChange = (itemId, quantity) => {
    if ((quantity > 0 && quantity < 1000) || quantity === '') {
      const newCartItems = [];

      for (let i = 0; i < cartItems.length; i += 1) {
        if (cartItems[i].id === itemId) {
          cartItems[i].quantity = Number(quantity);
          addItemToCart(cart.id, { id: itemId }, quantity);

          if (timer) {
            clearTimeout(timer);
          }

          timer = setTimeout(() => {
            saveItem(cart.id, { id: itemId });
          }, 1000);
        }
        newCartItems.push({ ...cartItems[i] });
      }

      setCartItems(newCartItems);
    }
  };

  const columns = React.useMemo(() => [
    {
      id: 'type',
      Header: '',
      accessor: (item) => item.type,
      Cell: ({ value }) => <TypeIcon className="react-table__type-icon" type={value} />,
    },
    {
      id: 'article',
      Header: 'Article',
      accessor: (item) => item.reference,
    },
    {
      id: 'name',
      Header: 'Libellé',
      accessor: (item) => item.name,
    },
    {
      id: 'boxStock',
      Header: 'Stk colis',
      accessor: (item) => (Number(item.value_packing) > 0 ? item.value_packing : '0'),
    },
    {
      id: 'Pcb',
      Header: 'PCB',
      accessor: (item) => (Number(item.pcb) > 0 ? item.pcb : '0'),
    },
    {
      id: 'BoxPrice',
      Header: 'Prix colis',
      accessor: (product) => {
        const { pcb, price, quantity } = product;
        const discountedPrice = getDegressivePrice(product);

        return (
          <div className="price-cell">
            <div className="price-cell__package">
              {discountedPrice !== price && quantity > 0 ? (
                <>
                  <span className="is-discounted">
                    <span className="price">{formatPriceWithCurrency(price * pcb)}</span>
                  </span>
                  <span className="is-normal">
                    {formatPriceWithCurrency(discountedPrice * pcb)}
                    <PromotionIcon />
                  </span>
                </>
              ) : (
                <>{formatPriceWithCurrency(price * pcb)}</>
              )}
            </div>
            <DegressivePrices product={product} />
          </div>
        );
      },
    },
    {
      id: 'quantity',
      Header: 'Qté',
      accessor: (item) => (
        <div className="commercial-order__table-product-quantity__container">
          <input
            className="commercial-order__table-product-quantity"
            onChange={(event) => {
              onProductQuantityChange(item.id, event.target.value);
            }}
            onFocus={(event) => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value <= 0 || event.target.value === '') {
                onProductQuantityChange(item.id, 1);
              }
            }}
            type="text"
            value={Number(item.quantity) > 0 ? item.quantity : ''}
          />
          {item.stock.value_packing < item.quantity && <WarningIcon withTooltip />}
        </div>
      ),
    },
    {
      id: 'total',
      Header: 'Total €',
      accessor: (item) => (
        <>
          {formatPrice((item.price_negociated || item.applicable_price) * item.pcb * item.quantity)}{' '}
          € HT
        </>
      ),
    },
    {
      id: 'delete',
      Header: '',
      accessor: (item) => item.id,
      Cell: ({ value }) => (
        <BsTrash
          onClick={() => {
            deleteCartItem(value);
          }}
          style={{ fill: 'red', cursor: 'pointer' }}
        />
      ),
    },
  ]);

  const getTotalQuantity = () => {
    let count = 0;
    cartItems.forEach((item) => {
      count += Number(item.quantity);
    });

    return count;
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice +=
        Number(item.price_negociated || item.applicable_price) * item.pcb * item.quantity;
    });

    return totalPrice;
  };

  const getTotalPriceVat = () => {
    let totalVat = 0;
    cartItems.forEach((item) => {
      const price =
        Number(item.price_negociated || item.applicable_price) +
        (Number(item.price_negociated || item.applicable_price) * Number(item.vat)) / 100;
      totalVat += price * item.pcb * item.quantity;
    });

    return totalVat;
  };

  const availableTemperatures = getAvailableTemperatures(currentPlatform);

  const deleteCartItem = (itemId) => {
    const item = cart.cart_items.find((cartItem) => cartItem.item.id === itemId);
    if (item) {
      deleteFromCart(item.id);
      setCartItems([...cartItems.filter((item) => item.id !== itemId)]);
    }
  };

  const isAllDateFilled = () => {
    let ret = true;
    availableTemperatures.forEach((temperature) => {
      const tempsHasItem = cartItems.find((cartItem) => cartItem.type === temperature.value);
      ret =
        (tempsHasItem && deliveryDate && deliveryDate[temperature.value]) || !tempsHasItem
          ? ret
          : false;
    });

    return ret;
  };

  const handleDatesChange = (date, type) => {
    if (cart) {
      const {
        delivery_date_dry: deliveryDateDry,
        delivery_date_fresh: deliveryDateFresh,
        delivery_date_frozen: deliveryDateFrozen,
      } = cart || {};

      const deliveryDates = {
        delivery_date_dry:
          type === catalogConstants.TEMPERATURE_DRY || type === 'all'
            ? getFormatedDate(date)
            : deliveryDateDry,
        delivery_date_fresh:
          type === catalogConstants.TEMPERATURE_FRESH || type === 'all'
            ? getFormatedDate(date)
            : deliveryDateFresh,
        delivery_date_frozen:
          type === catalogConstants.TEMPERATURE_FROZEN || type === 'all'
            ? getFormatedDate(date)
            : deliveryDateFrozen,
      };

      updateDeliveryDates(cart, deliveryDates);
    }
  };

  const totalPrice = getTotalPrice();
  const totalPriceVat = getTotalPriceVat();
  const totalVat = totalPriceVat - totalPrice;

  return (
    <CommercialOrderWrapper>
      <div className="commercial-order__form-container">
        <CommercialOrderForm
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setSelectedClient={setSelectedClient}
          selectedClient={selectedClient}
          setCartItems={setCartItems}
          cartItems={cartItems}
          setShouldBlockNavigation={setShouldBlockNavigation}
          cart={cart}
          setIsCartInitialized={setIsCartInitialized}
        />
      </div>
      <div className="commercial-order__table-container">
        <div className="commercial-order__table">
          <ReactTable columns={columns} data={cartItems} />
        </div>
        <div className="commercial-order__summary">
          <div className="commerial-order__line-counter">
            <div>
              {cartItems.length} {cartItems.length < 2 ? 'Ligne' : 'Lignes'}
            </div>
            <div>{getTotalQuantity()} Colis</div>
          </div>
          <table className="commercial-order__price-container">
            <tbody>
              <tr>
                <td className="commercial-order__bold">Montant HT €</td>
                <td>{formatPrice(totalPrice)}</td>
              </tr>
              <tr>
                <td className="commercial-order__bold">Montant TVA €</td>
                <td>{formatPrice(totalVat <= 0 ? 0 : totalVat)}</td>
              </tr>
              <tr>
                <td className="commercial-order__bold">Montant TTC €</td>
                <td>{formatPrice(totalPriceVat)}</td>
              </tr>
            </tbody>
          </table>
          <form className="commercial-order__delivery-form">
            <div className="commercial-order__delivery-container">
              <div className="commercial-order__delivery-title">Dates de Livraison</div>
              <div>
                {availableTemperatures.map((item) => (
                  <div key={uuid()} className="commercial-order__delivery-item">
                    <label>{item.title}</label>
                    <Calendar
                      onChange={(date) => {
                        handleDatesChange(date, item.value);
                        const newDate = { ...deliveryDate };
                        newDate[item.value] = date;

                        return setDeliveryDate(newDate);
                      }}
                      selected={
                        item && deliveryDate && deliveryDate[item.value]
                          ? Moment(deliveryDate[item.value]).toDate()
                          : ''
                      }
                      filterDate={(date) =>
                        isValidDeliveryDay(
                          date,
                          item.value,
                          { client: selectedClient },
                          dayOff,
                          currentPlatform
                        )
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/aaaa"
                      disabled={!cartItems.find((cartItem) => cartItem.type === item.value)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="commercial-order__comment-container">
              <label>Commentaire</label>
              <textarea
                onChange={(event) => {
                  setComment(event.target.value);
                }}
                onBlur={(event) => {
                  updateCart(cart, { comment: event.target.value });
                }}
                disabled={!cart || !cartItems.length}
                value={comment}
              />
            </div>
            <div className="commercial-order__action-container">
              <div className="commercial-order__sub-action-container">
                <ButtonPrimary
                  disabled={!cartItems.length}
                  onClick={() => {
                    history.push(getPath('commercialCartList'));
                  }}
                >
                  Mettre en attente
                </ButtonPrimary>
                <ButtonPrimary
                  disabled={!cart || !cart.id}
                  onClick={() => {
                    openDeleteModal(cart.id, clearState);
                  }}
                >
                  Annuler la commande
                </ButtonPrimary>
              </div>
              <ButtonPrimary
                className="commercial-order__validate-button"
                onClick={() => {
                  openCommercialOrderValidationModal(cart, shouldBlockNavigation);
                }}
                disabled={!deliveryDate || !isAllDateFilled() || (storeCart && storeCart.isLoading)}
              >
                {storeCart && storeCart.isLoading ? <Spinner size={20} /> : 'Valider la commande'}
              </ButtonPrimary>
            </div>
          </form>
        </div>
      </div>
    </CommercialOrderWrapper>
  );
};

CommercialOrder.propTypes = {
  currentPlatform: PropTypes.object,
  fetchDayOff: PropTypes.func,
  dayOff: PropTypes.array,
  openCommercialOrderValidationModal: PropTypes.func,
  cartCollection: PropTypes.array,
  validatedCart: PropTypes.object,
  pushToast: PropTypes.func,
  setErrorToasted: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { dayOff, cart } = state;
  const currentPlatform = selectCurrentPlatform(state);
  const dayOffCollection = getCollection(
    dayOff.collections || [],
    currentPlatform ? { platform: currentPlatform.id } : {}
  );

  return {
    currentPlatform,
    storeCart: cart,
    dayOff: dayOffCollection.items || [],
    cartError: selectCartError(state),
    cartCollection: selectCartCollectionByFilter(state, {
      status: cartConstants.CART_STATUS_PENDING_VALIDATION,
      platform: currentPlatform.id,
    }),
    validatedCart: selectValidatedCart(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDayOff: (filter) => dispatch(dayOffActions.fetchDayOff(filter, 0, 100)),
  updateCart: (cartId, dataToUpdate) => dispatch(cartActions.updateCart(cartId, dataToUpdate)),
  saveItem: (cartId, cartItem) => {
    dispatch(cartItemsActions.saveItem(cartId, cartItem));
  },
  openCommercialOrderValidationModal: (cart, setShouldBlockNavigation) =>
    dispatch(
      modalActions.open('addCommercialOrder', {
        width: '400',
        cart,
        setShouldBlockNavigation,
      })
    ),
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
  deleteCart: (cartId) => dispatch(cartActions.deleteCart(cartId)),
  deleteFromCart: (cartItemId) => dispatch(cartItemsActions.deleteItem(cartItemId)),
  setErrorToasted: () => dispatch(cartActions.setErrorToasted()),
  updateDeliveryDates: (cart, deliveryDates) =>
    dispatch(cartActions.updateDeliveryDates(cart, deliveryDates)),
  addItemToCart: (cartId, item, quantity, cartName) =>
    dispatch(cartItemsActions.addItem(cartId, item, Number(quantity), {}, cartName)),
  openDeleteModal: (cartId, callback) =>
    dispatch(
      modalActions.open('delete', {
        modalHeader: '',
        id: cartId,
        deleteType: 'COMMERCIAL_CART',
        callback,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommercialOrder);
