import { getDegressivePrice } from 'helpers';

export function formatPriceWithCurrency(price) {
  return `${Number(price).toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}€ HT`;
}

export function getDiscountedPrice(product, cartItem) {
  const {
    applicable_price: promotionPrice,
    degressive_prices: degressivePrices,
    has_promotion: hasPromotion,
    price: productPrice,
    use_degressive_prices: hasDegressivePrices,
  } = product;

  const { quantity } = cartItem;

  let discountedPrice = 0;

  if (hasPromotion) {
    if (
      hasDegressivePrices &&
      degressivePrices !== undefined &&
      degressivePrices.length > 0 &&
      quantity !== 0
    ) {
      const degressivePrice = getDegressivePrice(cartItem);
      discountedPrice = promotionPrice < degressivePrice ? promotionPrice : degressivePrice;
    } else {
      discountedPrice = promotionPrice;
    }
  } else if (
    hasDegressivePrices &&
    degressivePrices !== undefined &&
    degressivePrices.length > 0 &&
    quantity !== 0
  ) {
    discountedPrice = getDegressivePrice(cartItem);
  } else {
    discountedPrice = productPrice;
  }

  return Number(discountedPrice);
}
