import styled, { keyframes } from 'styled-components';

const TooltipWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;
  left: ${(props) => props.coordX || 0}px;

  ${(props) =>
    props.tooltipPosition === 'top' &&
    `
    top: calc(${props.coordY}px - 40px);
  `}

  ${(props) =>
    props.tooltipPosition === 'bottom' &&
    `
    top: calc(${props.coordY}px + 30px);
  `}

  display: flex;
  align-items: center;
  min-height: 20px;
  padding: 7px 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.2), 0 0 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.2);

  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.15s linear;
  animation-delay: ${(props) => (props.isVisible ? '250ms' : '0')};
  animation-fill-mode: forwards;
  transition: visibility 0.15s linear;
  transition-delay: ${(props) => (props.isVisible ? '250ms' : '0')};

  .tooltip__label {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    color: #4a4a4a;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export default TooltipWrapper;
