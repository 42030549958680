/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { formatPriceWithCurrency, getCartItem, getDiscountedPrice } from 'helpers';
import { getProductUnit } from 'helpers/Product';
import DegressivePrices from 'components/Core/DegressivePrices';

const ProductPrice = ({ product }) => {
  if (product == null) {
    return null;
  }

  const { id, pcb, price, unit } = product;

  const cartItem = getCartItem(id);
  const { quantity } = cartItem;
  const discountedPrice = getDiscountedPrice(product, cartItem);

  return (
    <td className="header_product-price">
      <div className="price-cell">
        <div className="price-cell__package">
          {discountedPrice !== price && quantity > 0 && (
            <span className="is-discounted">
              <span className="price">{formatPriceWithCurrency(price * pcb)}</span>
            </span>
          )}
        </div>

        <div className="price-cell__unity">
          {formatPriceWithCurrency(discountedPrice)} / {getProductUnit(unit)}
        </div>

        <div className="price-cell__package">
          {formatPriceWithCurrency(discountedPrice * pcb)} / colis
        </div>

        <DegressivePrices product={product} />
      </div>
    </td>
  );
};

ProductPrice.propTypes = {
  product: PropTypes.object,
};

export default ProductPrice;
