/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uniq from 'lodash.uniq';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { platformActions } from 'actions';
import { platformConstants } from 'pages/../constants';
import ConfiguratorSwitch from './ConfiguratorSwitch';
import ConfiguratorText from './ConfiguratorText';
import withStyle from './Configurator.style';

const {
  STOCK_DISPLAY_OUT_KEY,
  STOCK_CART_KEY,
  ORDERED_BY_KEY,
  NEW_DELAY_KEY,
  DELIVERY_MODE_KEY,
  HAS_DRY_PRODUCTS_KEY,
  HAS_FRESH_PRODUCTS_KEY,
  HAS_FROZEN_PRODUCTS_KEY,
  HAS_MOQ_KEY,
  HAS_TECHNICAL_SHEETS_KEY,
  HAS_CREDIT_KEY,
  HAS_CONTAINERIZATION_KEY,
  KEY_HAS_CONTAINERIZATION_V2,
  NEGOCIABLE_PERCENTAGE,
  HAS_PLATFORM_EXPORT_KEY,
  KEY_HAS_EXPORT_CATALOG_FILE,
  USE_DEGRESSIVE_PRICE,
} = platformConstants;

const textData = [
  { key: 'name', label: 'Nom', required: 'required' },
  { key: 'address', label: 'Adresse' },
  { key: 'zipcode', label: 'Code postale' },
  { key: 'city', label: 'Ville' },
  { key: 'legals', label: 'Mentions légales' },
  { key: 'cookie_path', label: 'Lien vers le PDF cookie' },
  { key: 'privacy_path', label: 'Lien vers le PDF RGPD' },
  { key: 'email_commitment', label: 'Email pour la demande de stock supplémentaire' },
  { key: 'email_credit', label: 'Email pour la demande d\u2019avoir' },
  { key: 'tel_accounting', label: 'Téléphone service comptabilité' },
  { key: 'fax_accounting', label: 'Fax service comptabilité' },
  { key: 'email_accounting', label: 'Email servive comptabilité' },
  { key: 'tel_technical', label: 'Téléphone service technique' },
  { key: 'fax_technical', label: 'Fax service technique' },
  { key: 'email_technical', label: 'Email service technique' },
];

const switchData = {
  Moq: HAS_MOQ_KEY,
  'Produits secs': HAS_DRY_PRODUCTS_KEY,
  'Produits surgelés': HAS_FROZEN_PRODUCTS_KEY,
  'Produits frais': HAS_FRESH_PRODUCTS_KEY,
  'Afficher la fiche technique du produit': HAS_TECHNICAL_SHEETS_KEY,
  'Ne pas afficher de message sur la disponibilité du stock': STOCK_DISPLAY_OUT_KEY,
  'Permettre l\u2019ajout au panier si le stock est <= 0': STOCK_CART_KEY,
  'Ajouter l\u2019enlévement au mode de livraison': DELIVERY_MODE_KEY,
  'Les items sont nouveaux pendant 60 jours (vs 90)': NEW_DELAY_KEY,
  'Les items sont par colis': ORDERED_BY_KEY,
  // Contenerisation: HAS_CONTAINERIZATION_KEY,
  Conteneurisation: KEY_HAS_CONTAINERIZATION_V2,
  'Demandes d\u2019avoir': HAS_CREDIT_KEY,
  'Pourcentage négociation %': NEGOCIABLE_PERCENTAGE,
  'Fournisseur Export': HAS_PLATFORM_EXPORT_KEY,
  'Exporter le catalogue en excel': KEY_HAS_EXPORT_CATALOG_FILE,
  'Tarifs dégressifs': USE_DEGRESSIVE_PRICE,
};

class Configurator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      platform: {
        attributs: [],
      },
    };
    this.getAttributByKey = this.getAttributByKey.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.toggleSet = this.toggleSet.bind(this);
    this.updatePlatformValues = this.updatePlatformValues.bind(this);
    this.validate = this.validate.bind(this);
    this.addAttributToState = this.addAttributToState.bind(this);
  }

  componentDidMount() {
    const { platform } = this.props;
    this.setState({ platform: { ...platform } });
  }

  componentDidUpdate(prevProps) {
    const { platform } = this.props;
    const { platform: prevPlatform } = prevProps;
    if (Number(platform.id) !== Number(prevPlatform.id)) {
      this.setState({ platform: { ...platform } });
    }
  }

  getAttributByKey(key) {
    const { attributs } = this.state.platform;
    const def = {
      value: 0,
      key,
    };
    const attribut = attributs.find((attribut) => attribut.key === key);
    if (!attribut) {
      this.addAttributToState(def);
    }
    return attribut || def;
  }

  getPlatformValueByKey(key) {
    const { platform } = this.state;
    return {
      key,
      value: platform[key] !== undefined ? platform[key] : '',
    };
  }

  addAttributToState(attribut) {
    const { platform } = this.state;
    platform.attributs.push(attribut);
    this.setState({ platform });
  }

  toggleSwitch(attributKey) {
    const { platform } = this.state;
    const { attributs } = platform;
    const newAttributs = attributs.map((attribut) => {
      const { key, value } = attribut;
      if (key === attributKey) {
        return { ...attribut, value: Number(value) === 1 ? 0 : 1 };
      }
      return attribut;
    });
    this.setState({ platform: { ...platform, attributs: newAttributs } });
  }

  toggleSet(attributKey, attributValue) {
    const { platform } = this.state;
    const { attributs } = platform;
    const newAttributs = attributs.map((attribut) => {
      const { key } = attribut;
      if (key === attributKey) {
        return { ...attribut, value: attributValue };
      }
      return attribut;
    });
    this.setState({ platform: { ...platform, attributs: newAttributs } });
  }

  updatePlatformValues(key, value) {
    const { platform } = this.state;
    platform[key] = value;
    this.setState({ platform });
  }

  validate() {
    const { updatePlatform } = this.props;
    const { platform } = this.state;
    const attributs = uniq(platform.attributs.map((attribut) => attribut.key && attribut));
    platform.attributs = attributs;
    /** HACK Since we don't have time to add toaster or modal to confirm we prefer using small js stuffs to force reload */
    if (updatePlatform(platform) === undefined) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you want to reload')) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    }
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <form className="configurator-form">
          {textData.map((item) => (
            <ConfiguratorText
              attribut={this.getPlatformValueByKey(item.key)}
              required={item.required}
              onChange={this.updatePlatformValues}
              label={item.label}
            />
          ))}
          {Object.entries(switchData).map(([key, value]) =>
            value === NEGOCIABLE_PERCENTAGE ? (
              <ConfiguratorText
                attribut={this.getAttributByKey(value)}
                onChange={this.toggleSet}
                label={key}
              />
            ) : (
              <ConfiguratorSwitch
                attribut={this.getAttributByKey(value)}
                onChange={this.toggleSwitch}
                label={key}
              />
            )
          )}

          <ButtonPrimary onClick={this.validate} className="configurator-submit">
            <span>Valider</span>
          </ButtonPrimary>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePlatform: (platform) => {
    dispatch(platformActions.updatePlatform(platform));
  },
});

Configurator.propTypes = {
  className: PropTypes.string,
  platform: PropTypes.object,
  updatePlatform: PropTypes.func,
};

Configurator.defaultProps = {
  platform: {
    attributs: [],
  },
};

export default connect(null, mapDispatchToProps)(withStyle(Configurator));
