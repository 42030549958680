import React, { useCallback, useRef } from 'react';
import get from 'lodash.get';
import { AgGridReact } from 'ag-grid-react';
import { store } from 'helpers';
import { catalogService } from 'services';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import sidebarDefs from './sidebarDefinitions';
import columnDefs from './columnDefinitions';
import localeText from '../locales';
import './AggridTableView.scss';

const AggridTableView = ({
  onGridReady,
  onFirstDataRendered,
  onCellClicked,
  onFilterChanged,
  frameworkComponents,
  doesExternalFilterPass,
  isExternalFilterPresent,
  handleFilter,
  searchValue,
  source,
  hasResults,
}) => {
  const gridRef = useRef(null);

  let productsToGetProperties = {};

  const updateCatalogProperties = useCallback((response) => {
    response.forEach((item) => {
      if (gridRef.current !== null) {
        const rowNode = gridRef.current.api.getDisplayedRowAtIndex(item.aggrid_row_index);
        if (rowNode.data !== undefined && rowNode.data.already_get_properties !== true) {
          const rowToUpdate = rowNode.data;
          rowToUpdate.is_favorite = item.is_favorite;
          rowToUpdate.client_stock_quantity = item.client_stock_quantity;
          rowToUpdate.degressive_prices = item.degressive_prices;
          rowToUpdate.use_degressive_prices = item.use_degressive_prices;

          if (
            item.has_promotion !== undefined &&
            item.has_promotion &&
            item.promotion.display_as_promotion
          ) {
            rowToUpdate.applicable_price = item.applicable_price;
            rowToUpdate.applicable_price_vat = item.applicable_price_vat;
            rowToUpdate.has_promotion = item.has_promotion;

            rowToUpdate.promotion.price = item.promotion.price;
            rowToUpdate.promotion.promo_code = item.promotion.promo_code;
            rowToUpdate.promotion.display_as_promotion = item.promotion.display_as_promotion;
            rowToUpdate.promotion.stock_commitment_remaining =
              item.promotion.stock_commitment_remaining;
            rowToUpdate.promotion.stock_commitment_request =
              item.promotion.stock_commitment_request;
            rowToUpdate.promotion.stock_commitment = item.promotion.stock_commitment;
          }

          rowToUpdate.already_get_properties = true;

          if (item.is_favorite || item.use_degressive_prices) {
            const row = gridRef.current.api.getDisplayedRowAtIndex(rowNode.rowIndex);
            gridRef.current.api.redrawRows({ rowNodes: [row] });
          }

          rowNode.updateData(rowToUpdate);
        }
      }
    });

    productsToGetProperties = {};
  });

  const componentProperties = {
    loading: (params) => {
      const state = store.getState();
      if (params.data !== undefined) {
        if (params.data.already_get_properties === true) {
          return null;
        }
      }
      if (params.data !== undefined) {
        productsToGetProperties[params.rowIndex] = {
          id: params.data.id,
          product_id: params.data.product_id,
          aggrid_row_index: params.rowIndex,
        };
      }
      if (
        params.rowIndex === params.node.rowRenderer.lastRenderedRow &&
        Object.keys(productsToGetProperties).length > 0
      ) {
        let productsToGetPropertiesTOsend = Object.values(productsToGetProperties).slice(-30);
        if (
          productsToGetPropertiesTOsend.length === 2 &&
          productsToGetPropertiesTOsend[0].aggrid_row_index === 0 &&
          productsToGetPropertiesTOsend[1].aggrid_row_index < 30
        ) {
          const productsToGetPropertiesNewtoSend = {};
          productsToGetPropertiesNewtoSend[0] = {
            id: productsToGetPropertiesTOsend[0].id,
            product_id: productsToGetPropertiesTOsend[0].product_id,
            aggrid_row_index: productsToGetPropertiesTOsend[0].aggrid_row_index,
          };
          for (let i = 0; i < productsToGetPropertiesTOsend[1].aggrid_row_index; i += 1) {
            const rowNode = gridRef.current.api.getDisplayedRowAtIndex(i + 1);
            productsToGetPropertiesNewtoSend[rowNode.rowIndex] = {
              id: rowNode.data.id,
              product_id: rowNode.data.product_id,
              aggrid_row_index: rowNode.rowIndex,
            };
          }
          productsToGetPropertiesTOsend = productsToGetPropertiesNewtoSend;
        }
        const { platform } = state;
        const filter = {
          platform: platform.selectedId,
        };
        catalogService
          .getCatalogPropertiesV4(filter, JSON.stringify(productsToGetPropertiesTOsend))
          .then(
            (response) => {
              updateCatalogProperties(response);
            },
            (err) => {
              // console.log(err);
              return err;
            }
          );
      }
      return null;
    },
  };

  return (
    <div className="AggridContainer ag-theme-balham">
      {!hasResults && (
        <>
          <p className="ag-list__notice">
            Nous n’avons pas trouvé de produits correspondant votre recherche.
          </p>
          <p className="ag-list__title">Consultez les produits tendances chez nos clients :</p>
        </>
      )}
      <AgGridReact
        cacheBlockSize={100}
        columnDefs={columnDefs}
        components={componentProperties}
        doesExternalFilterPass={doesExternalFilterPass}
        frameworkComponents={frameworkComponents}
        handleFilter={handleFilter}
        isExternalFilterPresent={isExternalFilterPresent}
        loadingOverlayComponent="Spinner"
        localeText={localeText}
        maxConcurrentDatasourceRequests={2}
        onCellClicked={onCellClicked}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        overlayNoRowsTemplate=""
        pivotMode={false}
        ref={gridRef}
        rowHeight={45}
        rowModelType="infinite"
        rowSelection="single"
        searchValue={searchValue}
        sideBar={sidebarDefs}
        source={source}
        suppressRowClickSelection
        tooltipShowDelay={0}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  source: get(state, 'source', 'AggridTableView'),
});

AggridTableView.propTypes = {
  onGridReady: PropTypes.func,
  onFirstDataRendered: PropTypes.func,
  onCellClicked: PropTypes.func,
  onFilterChanged: PropTypes.func,
  frameworkComponents: PropTypes.object,
  doesExternalFilterPass: PropTypes.func,
  isExternalFilterPresent: PropTypes.func,
  searchValue: PropTypes.string,
  source: PropTypes.string,
};

export default connect(mapStateToProps)(AggridTableView);
