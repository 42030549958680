import React, { useState } from 'react';
import {
  formatPriceWithCurrency,
  getCartItem,
  getDiscountedPrice,
  getIconClassByType,
  getValueOfAttribut,
  store,
} from 'helpers';
import Icon from 'components/Icon/Icon';
import { ProductQuantity, ProductTotal } from 'components/Table/Cells/Products';
import ProductFavorite from 'components/ProductFavoriteV3/ProductFavorite';
import PromotionIcon from 'components/Icon/PromotionIcon';
import { productConstants } from 'constants/Product.constants';
import {
  getProductImageFromEan,
  getProductUnit,
  getPcbUnit,
  getProductType,
} from 'helpers/Product';
import Tooltip from 'components/Core/Tooltip/Tooltip';
import Moment from 'moment';
import { selectCurrentPlatform } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import DegressivePrices from 'components/Core/DegressivePrices';
import { platformConstants } from '../../constants';

const agListNameCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const { client_stock_quantity: clientStockQuantity, name } = product.data;

  const currentPlatform = selectCurrentPlatform(store.getState());
  const { attributs } = currentPlatform;
  const shouldDisplayStock = !Number(
    getValueOfAttribut(attributs || [], platformConstants.STOCK_DISPLAY_OUT_KEY)
  );
  const currentUser = selectCurrentUser(store.getState());
  const shouldDisplayClientStock = currentUser.client.client_stock_display;

  return (
    <div className="ag-list__cell-wrapper">
      <div style={{ fontWeight: 'bold' }}>{name}</div>
      {!!shouldDisplayStock &&
        availabilityCellRenderer({ value: availabilityValueGetter(product) })}
      {shouldDisplayClientStock && clientStockCellRenderer(clientStockQuantity)}
    </div>
  );
};

const isPlatformLogigua = () => {
  if (parseInt(localStorage.getItem('platform'), 10) === 6) {
    return true;
  }
  return false;
};

const agListReferencesCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const { ean13, reference, sale_deadline: saleDeadline } = product.data;

  return (
    <div className="ag-list__cell-wrapper">
      <div>Réf: {reference}</div>
      <div>EAN: {ean13}</div>
      <div>
        DLV:{' '}
        {isPlatformLogigua() === false && saleDeadline
          ? Moment(saleDeadline).format('DD.MM.Y')
          : ' - '}
      </div>
    </div>
  );
};

const agListPricesCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const {
    degressive_prices: degressivePrices,
    use_degressive_prices: hasDegressivePrices,
    id,
    pcb,
  } = product.data;

  const cartItem = getCartItem(id);
  const { quantity } = cartItem;
  const currentPlatform = selectCurrentPlatform(store.getState());
  const { attributs } = currentPlatform;

  const shouldDisplayOrderedBy = Number(
    getValueOfAttribut(attributs || [], platformConstants.ORDERED_BY_KEY)
  );

  return (
    <div
      className={`ag-list__cell-wrapper price-cell ${
        hasDegressivePrices &&
        degressivePrices !== undefined &&
        degressivePrices.length > 0 &&
        quantity > degressivePrices[0].step &&
        'has-discount'
      }`}
    >
      <div className="price-cell__unity">{priceCellRenderer(product, cartItem)}</div>

      {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1 && (
        <div className="price-cell__package">{packagePriceCellRenderer(product, cartItem)}</div>
      )}

      <DegressivePrices product={product.data} />

      {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1 && (
        <div>
          PCB: {pcb} {getPcbUnit(product.data)}
        </div>
      )}
    </div>
  );
};

const favoriteCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const {
    has_promotion: hasPromotion,
    is_favorite: isFavorite,
    is_new: isNew,
    product_id: productId,
  } = product.data;

  return (
    <div className="favorite-cell__wrapper">
      <ProductFavorite shouldShowResponsive productId={productId} isFavorite={isFavorite} />
      {hasPromotion && <PromotionIcon />}
      {isNew && <Icon icon="icon-new" slug="is_new" color="red" />}
    </div>
  );
};

const agListImageCellRenderer = (product) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  if (product.data == null) {
    return null;
  }

  const { ean13, type } = product.data;

  return (
    <div className="ag-list__image-cell__wrapper">
      {imageCellRenderer({ value: ean13 })}
      <div className="ag-list__image-cell__type-icon-container">
        <Tooltip
          isVisible={isTooltipVisible}
          tooltipPosition="right"
          labelValue={getProductType(type)}
          parentElement={parentElement}
        />
        <i
          className={`${getIconClassByType(type)}`}
          onMouseEnter={(event) => {
            setIsTooltipVisible(true);
            setParentElement(event.target);
          }}
          onMouseLeave={() => {
            setIsTooltipVisible(false);
          }}
        />
      </div>
    </div>
  );
};

const imageCellRenderer = (ean13) => (
  <img
    src={getProductImageFromEan(ean13.value, productConstants.IMAGE_SIZE_SMALL)}
    alt={`${ean13.value}`}
    style={{ width: '35px', height: '35px' }}
    className="img-cell__img"
  />
);

const priceCellRenderer = (product, cartItem) => {
  if (product.data == null) {
    return null;
  }

  const { price, unit } = product.data;
  const { quantity } = cartItem;
  const discountedPrice = getDiscountedPrice(product.data, cartItem);

  return (
    <>
      {discountedPrice !== price && quantity > 0 ? (
        <>
          <span className="is-discounted">
            <span className="price">{formatPriceWithCurrency(price)}</span>
          </span>
          <span className="is-normal">
            {formatPriceWithCurrency(discountedPrice)} / {getProductUnit(unit)}
          </span>
        </>
      ) : (
        <>
          {formatPriceWithCurrency(price)} / {getProductUnit(unit)}
        </>
      )}
    </>
  );
};

const agTablePriceCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const { id, price } = product.data;
  const cartItem = getCartItem(id);
  const { quantity } = cartItem;
  const discountedPrice = getDiscountedPrice(product.data, cartItem);

  return (
    <>
      {discountedPrice !== price && quantity > 0
        ? formatPriceWithCurrency(discountedPrice)
        : formatPriceWithCurrency(price)}
    </>
  );
};

const packagePriceCellRenderer = (product, cartItem) => {
  if (product.data == null) {
    return null;
  }

  const { pcb, price, unit } = product.data;
  const { quantity } = cartItem;
  const discountedPrice = getDiscountedPrice(product.data, cartItem);

  return (
    <>
      {discountedPrice !== price && quantity > 0 ? (
        <>
          <span className="is-discounted">
            <span className="price">{formatPriceWithCurrency(price * pcb)}</span>
          </span>
          <span className="is-normal">
            {formatPriceWithCurrency(discountedPrice * pcb)} / {getProductUnit(unit)}
          </span>
        </>
      ) : (
        <>
          {formatPriceWithCurrency(price * pcb)} / {getProductUnit(unit)}
        </>
      )}
    </>
  );
};

const agTablePackagePriceCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const { id, pcb, price } = product.data;
  const cartItem = getCartItem(id);
  const { quantity } = cartItem;
  const discountedPrice = getDiscountedPrice(product.data, cartItem);

  return (
    <>
      {discountedPrice !== price && quantity > 0
        ? formatPriceWithCurrency(discountedPrice * pcb)
        : formatPriceWithCurrency(price * pcb)}
    </>
  );
};

const availabilityCellRenderer = (params) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  let dotClassName = null;
  let availabilityLabel = null;
  const currentPlatform = selectCurrentPlatform(store.getState());
  const { attributs } = currentPlatform;
  const shouldDisplayStock = !Number(
    getValueOfAttribut(attributs || [], platformConstants.STOCK_DISPLAY_OUT_KEY)
  );

  if (!shouldDisplayStock) {
    return '-';
  }

  switch (params.value) {
    case 'preorder':
      dotClassName = 'orange_background';
      availabilityLabel = 'En Précommande';
      break;
    case 'ok':
      dotClassName = 'green_background';
      availabilityLabel = 'En Stock';
      break;
    default:
      dotClassName = 'red_background';
      availabilityLabel = 'Indisponible';
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span className={`aggrid__dot ${dotClassName}`} />
      <span
        style={{ position: 'relative' }}
        onMouseEnter={(event) => {
          setIsTooltipVisible(true);
          setParentElement(event.target);
        }}
        onMouseLeave={() => {
          setIsTooltipVisible(false);
        }}
      >
        <Tooltip
          isVisible={isTooltipVisible}
          tooltipPosition="top"
          labelValue="Stock du fournisseur"
        />
        {availabilityLabel}
      </span>
    </div>
  );
};

const clientStockCellRenderer = (clientStock) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  if (!clientStock && clientStock !== 0) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="aggrid__dot grey_background" />
        <span
          style={{ position: 'relative' }}
          onMouseEnter={(event) => {
            setIsTooltipVisible(true);
            setParentElement(event.target);
          }}
          onMouseLeave={() => {
            setIsTooltipVisible(false);
          }}
        >
          <Tooltip
            isVisible={isTooltipVisible}
            tooltipPosition="top"
            labelValue="Stock du magasin"
            parentElement={parentElement}
          />
          Votre stock: hors assortiment
        </span>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span className={`aggrid__dot ${clientStock > 0 ? 'green_background' : 'red_background'}`} />
      <span
        style={{ position: 'relative' }}
        onMouseEnter={(event) => {
          setIsTooltipVisible(true);
          setParentElement(event.target);
        }}
        onMouseLeave={() => {
          setIsTooltipVisible(false);
        }}
      >
        <Tooltip
          isVisible={isTooltipVisible}
          tooltipPosition="top"
          labelValue="Stock du magasin"
          parentElement={parentElement}
        />
        Votre stock: {clientStock}
      </span>
    </div>
  );
};

const agTableQuantityCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const { is_favorite: isFavorite, product_id: productId } = product.data;

  return (
    <div className="aggrid__quantity-container">
      <ProductFavorite
        shouldShowResponsive
        productId={productId}
        isFavorite={isFavorite}
        handleClickFilter={product.agGridReact.props.handleFilter}
      />
      {quantityCellRenderer(product)}
    </div>
  );
};

const agListQuantityCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return (
    <div className="ag-list__cell-wrapper ag-list-quantity-wrapper">
      {quantityCellRenderer(product)}
      <div className="ag-list__cell-wrapper__total-cell">{totalCellRenderer(product)}</div>
    </div>
  );
};

const agListTabletRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const { name } = product.data;

  return (
    <div className="ag-list__cell-wrapper ag-list-tablet-wrapper">
      <div style={{ fontWeight: 'bold' }}>{name}</div>
      {agListPricesCellRenderer(product)}
    </div>
  );
};

const agListMobileRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  const { name } = product.data;

  return (
    <div className="ag-list__cell-wrapper ag-list-mobile-wrapper">
      <div style={{ fontWeight: 'bold' }}>{name}</div>
      {agListPricesCellRenderer(product)}
      <div className="ag-list-mobile__quantity-wrapper">
        {quantityCellRenderer(product)}
        {totalCellRenderer(product)}
      </div>
    </div>
  );
};

const quantityCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return <ProductQuantity shouldShowResponsive product={product.data} />;
};

const totalCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }

  return <ProductTotal shouldShowResponsive product={product.data} float={null} />;
};

const isNewRenderer = (isNew) => (isNew.value === true || isNew.value === 'true' ? 'OUI' : 'NON');

const departmentCellRenderer = (department) => {
  return getProductType(department.value);
};

const availabilityValueGetter = (stock) => {
  if (stock.data == null) {
    return null;
  }

  if (stock.data && stock.data.is_preorder === true) {
    return 'preorder';
  }

  if (stock.data.stock && stock.data.stock.value_cu >= stock.data.pcb) {
    return 'ok';
  }

  return 'ko';
};

const availabilityFilterFormatter = (stock) => {
  if (stock == null) {
    return null;
  }

  if (stock.value === 'preorder') {
    return 'En Précommande';
  }

  if (stock.value === 'ok') {
    return 'En Stock';
  }

  return 'Indisponible';
};

const hasPromotionRenderer = (promotionId) =>
  promotionId.data.promotion_id === null ? 'NON' : 'OUI';

export {
  imageCellRenderer,
  priceCellRenderer,
  packagePriceCellRenderer,
  availabilityCellRenderer,
  quantityCellRenderer,
  totalCellRenderer,
  isNewRenderer,
  departmentCellRenderer,
  availabilityValueGetter,
  availabilityFilterFormatter,
  hasPromotionRenderer,
  agListNameCellRenderer,
  agListReferencesCellRenderer,
  agListPricesCellRenderer,
  agTablePackagePriceCellRenderer,
  agTablePriceCellRenderer,
  agTableQuantityCellRenderer,
  agListQuantityCellRenderer,
  favoriteCellRenderer,
  agListTabletRenderer,
  agListMobileRenderer,
  agListImageCellRenderer,
  clientStockCellRenderer,
};
